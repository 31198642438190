@charset "UTF-8";
/* background-color: #fc9842;
background-image: linear-gradient(315deg, #fc9842 0%, #fe5f75 74%); */
body {
    font-family: 'Rubik', sans-serif;
    position: relative;
}

a {
    color: #e38cb7;
}

a:hover,
a:focus {
    color: #d6619c;
}

h1 {
    font-size: 60px;
    font-weight: 300;
    letter-spacing: -1px;
    margin-bottom: 1.5rem;
}

h2 {
    font-size: 45px;
    font-weight: 300;
    color: #633991;
    letter-spacing: -1px;
    margin-bottom: 1rem;
}

h3 {
    color: #633991;
    font-size: 33px;
    font-weight: 500;
}

h4 {
    font-size: 20px;
    font-weight: 500;
    color: #633991;
}

h5 {
    font-size: 28px;
    font-weight: 300;
    color: #633991;
    margin-bottom: 0.7rem;
}

p {
    color: #959094;
}

p.lead {
    color: #e38cb7;
    margin-bottom: 2rem;
}

.text-primary {
    color: #e38cb7 !important;
}

.light-font {
    font-weight: 300;
}

.btn {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0.375rem 1.35rem;
    transition: all 0.3s ease;
}

.btn-outline-light:hover {
    color: #d6619c;
}

.btn-primary {
    border-radius: 3px;
    background-image: -moz-linear-gradient(122deg, #e54595 0%, #fd378e 100%);
    background-image: -webkit-linear-gradient(122deg, #e54595 0%, #fd378e 100%);
    background-image: -ms-linear-gradient(122deg, #e54595 0%, #fd378e 100%);
    background-image: linear-gradient(122deg, #e54595 0%, #fd378e 100%);
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.2);
    font-weight: 500;
    padding: 0.6rem 2rem;
    border: 0;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background-image: linear-gradient(122deg, #fd378e 0%, #e54595 100%);
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.3);
    color: #FFF;
}

.btn-light {
    border-radius: 3px;
    background: #FFF;
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.26);
    font-size: 14px;
    font-weight: 500;
    color: #633991;
    margin: 0.5rem;
    padding: 0.7rem 1.6rem;
    line-height: 1.8;
}

.btn-group-lg>.btn,
.btn-lg {
    padding: 0.8rem 1rem;
    font-size: 15px;
}

.light-bg {
    background-color: #faf6fb;
}

.section {
    padding: 80px 0;
}

.section-title {
    text-align: center;
    margin-bottom: 3rem;
}

.section-title small {
    color: #998a9b;
}

@media (max-width:767px) {
    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 30px;
    }
}


/* NAVBAR
----------------------*/

.nav-menu {
    padding: 1rem 0;
    transition: all 0.3s ease;
}

.nav-menu.is-scrolling,
.nav-menu.menu-is-open {
    background-color: #fc9842;
    background-image: linear-gradient(315deg, #fc9842 0%, #fe5f75 74%);
    background-image: -moz-linear-gradient(315deg, #fc9842 0%, #fe5f75 74%);
    background-image: -webkit-linear-gradient(315deg, #fc9842 0%, #fe5f75 74%);
    /* background-color: rgb(74, 13, 143);
    background: -moz-linear-gradient(135deg, rgb(74, 13, 143) 0%, rgb(250, 42, 143) 100%);
    background: -webkit-linear-gradient(135deg, rgb(74, 13, 143) 0%, rgb(250, 42, 143) 100%);
    background: linear-gradient(135deg, rgb(74, 13, 143) 0%, rgb(250, 42, 143) 100%); */
    -webkit-box-shadow: 0px 5px 23px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 23px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 23px 0px rgba(0, 0, 0, 0.1);
}

.nav-menu.is-scrolling {
    padding: 0;
}

.navbar-nav .nav-link {
    position: relative;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
        font-size: 14px;
    }

    .navbar-nav>.nav-item>.nav-link.active:after {
        content: "";
        border-bottom: 2px solid #fff;
        left: 1rem;
        right: 1rem;
        bottom: 5px;
        height: 1px;
        position: absolute;
    }
}

@media (max-width:991px) {
    .navbar-nav.is-scrolling {
        padding-bottom: 1rem;
    }

    .navbar-nav .nav-item {
        text-align: center;
    }
}


/* HERO
----------------------*/

header {
    padding: 100px 0 0;
    text-align: center;
    color: #FFF;
}

.bg-gradient {
    background-image: -moz-linear-gradient(135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
    background-image: -webkit-linear-gradient(135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
    background-image: -ms-linear-gradient(135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
    background-image: linear-gradient(135deg, rgba(60, 8, 118, 0.8) 0%, rgba(250, 0, 118, 0.8) 100%);
}

.tagline {
    font-size: 23px;
    font-weight: 300;
    color: #ffb8f6;
    max-width: 800px;
    margin: 0 auto;
}

.img-holder {
    height: 0;
    padding-bottom: 33%;
    overflow: hidden;
}

@media (max-width:1200px) {
    .img-holder {
        padding-bottom: 50%;
    }
}

@media (max-width:767px) {
    .tagline {
        font-size: 17px;
    }

    .img-holder {
        padding-bottom: 100%;
    }
}


/* FEATURES
----------------------*/

.gradient-fill:before {
    color: #fc73b4;
    background: -moz-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: -webkit-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: linear-gradient(to bottom, #9477b4 0%, #fc73b4 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.card.features {
    border: 0;
    border-radius: 3px;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.04);
    transition: all 0.3s ease;
}

@media (max-width:991px) {
    .card.features {
        margin-bottom: 2rem;
    }

    [class^="col-"]:last-child .card.features {
        margin-bottom: 0;
    }
}

.card.features:before {
    content: "";
    position: absolute;
    width: 3px;
    color: #fc73b4;
    background: -moz-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: -webkit-linear-gradient(top, #9477b4 0%, #fc73b4 100%);
    background: linear-gradient(to bottom, #9477b4 0%, #fc73b4 100%);
    top: 0;
    bottom: 0;
    left: 0;
}

.card-text {
    font-size: 14px;
}

.card.features:hover {
    transform: translateY(-3px);
    -moz-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.08);
}

.box-icon {
    box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.14);
    padding: 10px;
    width: 70px;
    border-radius: 3px;
    margin-bottom: 1.5rem;
    background-color: #FFF;
}

.circle-icon {
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
    padding: 10px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 1.5rem;
    background-color: #FFF;
    color: #f5378e;
    font-size: 48px;
    text-align: center;
    line-height: 80px;
    font-weight: 300;
    transition: all 0.3s ease;
}

@media (max-width:992px) {
    .circle-icon {
        width: 70px;
        height: 70px;
        font-size: 28px;
        line-height: 50px;
    }
}

.ui-steps li:hover .circle-icon {
    background-image: -moz-linear-gradient(122deg, #e6388e 0%, #fb378e 100%);
    background-image: -webkit-linear-gradient(122deg, #e6388e 0%, #fb378e 100%);
    background-image: -ms-linear-gradient(122deg, #e6388e 0%, #fb378e 100%);
    background-image: linear-gradient(122deg, #e6388e 0%, #fb378e 100%);
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.09);
    color: #FFF;
}

.ui-steps li {
    padding: 15px 0;
}

.ui-steps li:not(:last-child) {
    border-bottom: 1px solid #f8e3f0;
}

.perspective-phone {
    position: relative;
    z-index: -1;
}

@media (min-width:992px) {
    .perspective-phone {
        margin-top: -150px;
    }
}


/*  TABS
----------------------*/

.tab-content {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: #FFF;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.04);
    padding: 3rem;
}

@media (max-width:992px) {
    .tab-content {
        padding: 1.5rem;
    }
}

.tab-content p {
    line-height: 1.8;
}

.tab-content h2 {
    margin-bottom: 0.5rem;
}

.nav-tabs {
    border-bottom: 0;
}

.nav-tabs .nav-item .nav-link,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    padding: 1rem 1rem;
    border-color: #faf6fb #faf6fb #FFF;
    font-size: 19px;
    color: #b5a4c8;
    background: #f5eff7;
}

.nav-tabs .nav-link.active {
    background: #FFF;
    border-top-width: 3px;
    border-color: #ce75b4 #faf6fb #FFF;
    color: #633991;
}


/*  TESTIMONIALS
----------------------*/

.owl-carousel .owl-item img.client-img {
    width: 110px;
    margin: 30px auto;
    border-radius: 50%;
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
}

.testimonials-single {
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
}

.blockquote {
    color: #7a767a;
    font-weight: 300;
}

.owl-next.disabled,
.owl-prev.disabled {
    opacity: 0.5;
}

.owl-prev,
.owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.owl-prev {
    left: 0;
}

.owl-next {
    right: 0;
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 60px;
}

.owl-theme .owl-dots .owl-dot span {
    background: #e7d9eb;
    width: 35px;
    height: 8px;
    border-radius: 10px;
    transition: all 0.3s ease-in;
}

.owl-theme .owl-dots .owl-dot:hover span {
    background: #ff487e;
}

.owl-theme .owl-dots .owl-dot.active span {
    background: #ff487e;
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
}


/*  IMAGE GALLERY
----------------------*/

.img-gallery .owl-item {
    box-shadow: 0px 9px 32px 0px rgba(0, 0, 0, 0.07);
    transform: scale(0.8);
    transition: all 0.3s ease-in;
}

.img-gallery .owl-item.center {
    transform: scale(1);
}


/*  PRICING
----------------------*/

@media (max-width:992px) {
    .card-deck {
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .card-deck .card {
        margin-bottom: 15px;
    }

}

.card.pricing {
    border: 1px solid #f1eef1;
}

.card.pricing.popular {
    border-top-width: 3px;
    border-color: #ce75b4 #faf6fb #FFF;
    box-shadow: 0px 12px 59px 0px rgba(36, 7, 31, 0.11);
    color: #633991;
}

.card.pricing .card-head {
    text-align: center;
    padding: 40px 0 20px;
}

.card.pricing .card-head .price {
    display: block;
    font-size: 45px;
    font-weight: 300;
    color: #633991;
}

.card.pricing .card-head .price sub {
    bottom: 0;
    font-size: 55%;
}

.card.pricing .list-group-item {
    border: 0;
    text-align: center;
    color: #959094;
    padding: 1.05rem 1.25rem;
}

.card.pricing .list-group-item del {
    color: #d9d3d8;
}

.card.pricing .card-body {
    padding: 1.75rem;
}


/*  CALL TO ACTION
----------------------*/

.call-to-action {
    text-align: center;
    color: #FFF;
    margin: 3rem 0;
}

.call-to-action .box-icon {
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    transform: scale(0.85);
    margin-bottom: 2.5rem;
}

.call-to-action h2 {
    color: #FFF;
}

.call-to-action .tagline {
    font-size: 16px;
    font-weight: 300;
    color: #ffb8f6;
    max-width: 650px;
    margin: 0 auto;
}

.btn-light img {
    margin-right: 0.4rem;
    vertical-align: text-bottom;
}


/*  FOOTER
----------------------*/

.social-icons {
    /* text-align: right; */
    margin: 20px 0px;
}

.social-icons a {
    background-color: #FFF;
    box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.05);
    width: 50px;
    height: 50px;
    display: inline-block;
    text-align: center;
    line-height: 50px;
    margin: 0 0.3rem;
    border-radius: 5px;
    color: #f4c9e2;
    transition: all 0.3s ease;
}

.social-icons a:hover {
    text-decoration: none;
    color: #e38cb7;
}

@media (max-width:991px) {
    .social-icons {
        text-align: center;
        margin-top: 2rem;
    }
}

/* Carousel Clients */

#clients-carousel {
    position: relative;
    height: 400px;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
}

#clients-carousel div {
    position: absolute;
    transition: transform 1s, left 1s, opacity 1s, z-index 0s;
    opacity: 1;
}

#clients-carousel div img {
    width: 400px;
    transition: width 1s;
}

#clients-carousel div.hideLeft {
    left: 0%;
    opacity: 0;
    transform: translateY(50%) translateX(-50%);
}

#clients-carousel div.hideLeft img {
    width: 200px;
}

#clients-carousel div.hideRight {
    left: 100%;
    opacity: 0;
    transform: translateY(50%) translateX(-50%);
}

#clients-carousel div.hideRight img {
    width: 200px;
}

#clients-carousel div.prev {
    z-index: 5;
    left: 30%;
    transform: translateY(50px) translateX(-50%);
}

#clients-carousel div.prev img {
    width: 300px;
}

#clients-carousel div.prevLeftSecond {
    z-index: 4;
    left: 15%;
    transform: translateY(50%) translateX(-50%);
    opacity: 0.7;
}

#clients-carousel div.prevLeftSecond img {
    width: 200px;
}

#clients-carousel div.selected {
    z-index: 10;
    left: 50%;
    transform: translateY(0px) translateX(-50%);
}

#clients-carousel div.next {
    z-index: 5;
    left: 70%;
    transform: translateY(50px) translateX(-50%);
}

#clients-carousel div.next img {
    width: 300px;
}

#clients-carousel div.nextRightSecond {
    z-index: 4;
    left: 85%;
    transform: translateY(50%) translateX(-50%);
    opacity: 0.7;
}

#clients-carousel div.nextRightSecond img {
    width: 200px;
}

.buttons {
    /* position: relative;
    top: -150px; */
    text-align: center;
    margin-top: -160px;
}

.btn-outline-primary:focus {
    outline: none !important;
}

.main-bgmmmmm {
    background: url(../src/assets/images/main-bg.jpg);
    height: 100vh;
    background-color: #f8f8f8;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-content {
    position: absolute;
    z-index: 2;
    text-align: center;
    height: 100vh;
}

.main-bgmmmmm .overlay-bg {
    background-color: black;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    position: absolute;
    z-index: 1;
}

.video-section {
    height: 100vh;
    background: url(../src/assets/images/main-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Video Section */
.video-section .pattern-overlay {
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    min-height: 100%;
    /* Incase of overlay problems just increase the min-height*/
}

.video-section h1,
.video-section h3 {
    text-align: center;
    color: #fff;
}

.video-section h1 {
    /* font-weight: bold; */
    text-transform: uppercase;
    margin: 60px auto 0px;
    text-shadow: 1px 1px 1px #000;
    -webkit-text-shadow: 1px 1px 1px #000;
    -moz-text-shadow: 1px 1px 1px #000;
}

.video-section h3 {
    font-size: 25px;
    font-weight: lighter;
    margin: 0px auto 15px;
}

.form-control {
    background-color: transparent !important;
    border-radius: 0px !important;
    color: #959094 !important;
}

.form-group label {
    color: #959094 !important;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #959094 !important;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #959094 !important;
}

@media (min-width: 985px) {
    .test {
        padding-top: 110px;
    }
}

.navbar-brand{
    color: #fff !important;
    cursor: pointer;
}

.nav-item a{
    cursor: pointer;
}

.py-5 {
    padding-bottom: 4rem!important;
    padding-top: 5rem!important;
}

.btn:focus,.btn:active {
    outline: 0 !important;
    box-shadow: transparent !important;
 }

 /* .bg-gradient-about{
     background-image: url(./assets/images/abput-bg.png);
     background-repeat: no-repeat;
     background-size: cover;
 } */

 .section-about{
     padding: 80px 0px 150px 0px;
 }

 @media all and (min-width: 1200px) {
    .video-section h1 {
        font-size: 110px;
    }
    .video-section h3{
        font-size: 30px;
    }
 } 

 @media all and (max-width: 1199px) {
    .video-section h1 {
        font-size: 110px;
    }
    .video-section h3{
        font-size: 30px;
    }
 } 
@media all and (max-width: 991px) { 
    .video-section h1 {
        font-size: 90px;
    }
    .video-section h3{
        font-size: 25px;
    }
 } 
@media all and (max-width: 768px) { 
    .video-section h1 {
        font-size: 75px;
    }
    .video-section h3{
        font-size: 20px;
    }
    .mobile-top{
        order: 1;
    }
    .no-mobile-top{
        order: 2;
    }
 } 
@media all and (max-width: 575px) { 
    .video-section h1 {
        font-size: 60px;
    }
    .video-section h3{
        font-size: 20px;
    }
 }

 .services-head{
    color: #ff1481;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 20px;
 }

 .services-subhead{
    font-size: 18px;
    color:#222222;
 }

 .services-list{
     line-height: 28px;
 }

 .bg-gradient-about{
     background-color: #162b45;
 }